<template>
  <v-pagination v-if="pages > 1" v-model="page" :length="pages" total-visible="5" />
</template>

<script>
export default {
  props: {
    list: { type: Array, default: () => [] },
    listItemInit: { type: Object, default: () => null },
    itemsPerPage: { type: Number, default: 20 },
    startAtEnd: { type: Boolean, default: false },
  },
  data: () => ({
    page: 1,
  }),
  computed: {
    pages() {
      return Math.ceil(this.list.length / this.itemsPerPage)
    },
    listPaginated() {
      let start, end
      if (this.startAtEnd) {
        start = this.list.length - (this.pages - this.page + 1) * this.itemsPerPage
        end = this.list.length - (this.pages - this.page) * this.itemsPerPage
        if (start < 0) start = 0
      } else {
        start = (this.page - 1) * this.itemsPerPage
        end = this.page * this.itemsPerPage
      }
      return this.list.slice(start, end)
    },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        this.page = this.getItemPage(this.listItemInit)
      },
    },
    listPaginated: {
      immediate: true,
      handler(newVal) {
        this.$emit('list-paginated', newVal)
      },
    },
  },
  methods: {
    getItemPage(item) {
      let index = this.list.indexOf(item)
      if (this.startAtEnd) {
        if (index == -1) return this.pages || 1
        let indexFromEnd = this.list.length - 1 - index
        return this.pages - Math.floor(indexFromEnd / this.itemsPerPage) + 1
      } else {
        if (index == -1) return 1
        return Math.floor(index / this.itemsPerPage) + 1
      }
    },
  },
}
</script>
